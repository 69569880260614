<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Customer</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="id_perusahaan_customer"
                    >Perusahaan
                  </label>
                  <select
                    v-model="form.id_perusahaan_customer"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_perusahaan_customer = ''"
                    :class="{
                      'is-valid': form.id_perusahaan_customer != '',
                      'is-invalid':
                        formValidate.id_perusahaan_customer ||
                        form.id_perusahaan_customer == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="perusahaan in listPerusahaan"
                      :key="perusahaan.id_perusahaan_customer"
                      :value="perusahaan.id_perusahaan_customer"
                    >
                      {{ perusahaan.nama_perusahaan_customer }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="nama_customer"
                    >Nama Customer
                  </label>
                  <input
                    v-model="form.nama_customer"
                    type="email"
                    class="form-control"
                    placeholder="Input Nama Customer"
                    required
                    @input="formValidate.nama_customer = ''"
                    :class="{
                      'is-valid': form.nama_customer != '',
                      'is-invalid':
                        formValidate.nama_customer ||
                        form.nama_customer == '',
                    }"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="no_hp_customer"
                    >No Hp Customer
                  </label>
                  <input
                    v-model="form.no_hp_customer"
                    type="tel"
                    class="form-control"
                    placeholder="Input Hp Customer"
                    required
                    @input="formValidate.no_hp_customer = ''"
                    :class="{
                      'is-valid': form.no_hp_customer != '',
                      'is-invalid':
                        formValidate.no_hp_customer ||
                        form.no_hp_customer == '',
                    }"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="alamat_customer"
                    >Alamat Customer
                  </label>
                  <input
                    v-model="form.alamat_customer"
                    type="tel"
                    class="form-control"
                    placeholder="Input Alamat Customer"
                    required
                    @input="formValidate.alamat_customer = ''"
                    :class="{
                      'is-valid': form.alamat_customer != '',
                      'is-invalid':
                        formValidate.alamat_customer ||
                        form.alamat_customer == '',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
                <button @click="showModal" class="btn btn-warning ml-1">
                  Tambah Perusahaan Baru
                </button>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Tambah Perusahaan</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="bold" for="nama_perusahaan_customer"
                >Perusahaan
              </label>
              <input
                v-model="perusahaan.nama_perusahaan_customer"
                type="tel"
                class="form-control"
                placeholder="Input Perusahaan"
                required
                @input="formValidate.nama_perusahaan_customer = ''"
                :class="{
                  'is-valid': perusahaan.nama_perusahaan_customer != '',
                  'is-invalid':
                    formValidate.nama_perusahaan_customer ||
                    perusahaan.nama_perusahaan_customer == '',
                }"
              />
            </div>
          </div>
          <div class="col-md-12">
            <b-alert v-if="statusCategory" variant="success" show fade>
              <strong>Success!</strong>
              <br />
              <span>{{ perusahaanAction }} Perusahaan Sukses</span>
            </b-alert>
          </div>
          <div class="col-md-12">
            <div style="max-height: 50vh; overflow-y: scroll">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr class="text-center">
                    <th>Perusahaan</th>
                    <th style="width: 100px"><i class="fa fa-cogs"></i></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="perusahaan in listPerusahaan"
                    :key="perusahaan.id_perusahaan_customer"
                  >
                    <td>{{ perusahaan.nama_perusahaan_customer }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-icon btn-warning m-r-5"
                        v-on:click="
                          editPerusahaan(
                            perusahaan.id_perusahaan_customer,
                            perusahaan.nama_perusahaan_customer
                          )
                        "
                      >
                        <i class="fa fa-pencil-alt"></i>
                      </button>
                      <button
                        class="btn btn-icon btn-danger"
                        v-on:click="confirmDelete(perusahaan.id_perusahaan_customer)"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button @click="closeModal" class="btn btn-info" variant="primary">
          Tutup
        </button>
        <button
          class="btn btn-success"
          @click="postCatergory"
          variant="primary"
          :disabled="loadingCategory"
        >
          <i v-if="loadingCategory" class="fas fa-circle-notch fa-spin"></i>
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      form: {
        id_customer: "",
        id_perusahaan_customer: "",
        nama_customer: "",
        alamat_customer: "",
        no_hp_customer: "",
      },
      perusahaan: {
        id_perusahaan_customer: "",
        nama_perusahaan_customer: "",
      },
      listPerusahaan: [],
      formValidate: [],
      loading: false,
      loadingCategory: false,
      modalVisible: false,
      conditionMet: false,
      statusCategory: false,
      perusahaanAction: "Create",
    };
  },
  mounted() {
    this.getCatergoryCustomer();
  },
  methods: {
    getCatergoryCustomer() {
      axios
        .get(this.$url+"/v2/perusahaan_customer/")
        .then((response) => {
          this.listPerusahaan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(`${this.$url}/v2/customer/create/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postCatergory() {
      this.loadingCategory = true;
      const formData = new FormData();
      for (let key in this.perusahaan) {
        formData.append(key, this.perusahaan[key]);
      }
      axios
        .post(`${this.$url}/v2/perusahaan_customer/${this.perusahaanAction}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingCategory = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loadingCategory = false;
            this.statusCategory = true;
            setTimeout(() => {
              this.statusCategory = false;
              this.perusahaan.nama_perusahaan_customer = "";
              this.closeModal();
              this.getCatergoryCustomer();
            }, 1000);
          }
        })
        .catch((error) => {
          this.loadingCategory = false;
          console.log(error);
          this.error = error.message;
        });
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
      this.perusahaanAction = "Create";
      this.perusahaan.id_perusahaan_customer = "";
      this.perusahaan.nama_perusahaan_customer = "";
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get(`${this.$url}/v2/perusahaan_customer/delete/${id}`)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.getCatergoryCustomer();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Gagal",
              text: error.response.data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.getCatergoryCustomer();
            });
            console.log(error.response.data.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    editPerusahaan(id, name) {
      this.perusahaanAction = "Update";
      this.perusahaan.id_perusahaan_customer = id;
      this.perusahaan.nama_perusahaan_customer = name;
    },
  },
};
</script>